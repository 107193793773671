<template>

    <div style="background-color: slategrey;border-bottom-width: 20px">
        <van-notice-bar
                style="font-size: 18px"
                scrollable
                left-icon="volume-o"
                text="如果您在本软件使用中有什么疑问，欢迎您给予我们反馈。"
        />
        <van-nav-bar
                class="icon-back"
                title="意见反馈"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-form @submit="onSubmit">
        <van-field
                style="font-size: 20px"
                name="message"
                v-model="message"
                rows="2"
                autosize
                label="留言"
                type="textarea"
                maxlength="50"
                placeholder="请输入留言"
                show-word-limit
        />
        <van-button type="primary" color="#1989FA" native-type="submit" size="large" block>提交</van-button>
        </van-form>
    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";

    export default {
        name: "Feedback",
        created() {
          this.id = JSON.parse(this.$route.query.id);
        let token =  sessionStorage.getItem("token");
        axios.defaults.headers.common["satoken"] =  token;
        },
        data(){
            return{
                id:'',
                message: '',
            }
        },
        methods:{
            tolast(){
                this.$router.go(-1);
            },
            onSubmit(values) {
                // Toast(this.id)
                // console.log(values.message);
                axios.post('http://43.139.145.191:8081/custom/createComplaint',{orderId:this.id,content:values.message}).then((resp) => {
                    if (resp.data.status==200){
                        Dialog.alert({
                            title: '通知',
                            message: '反馈成功',
                        })
                        setTimeout(()=>{
                            history.go(-1);
                        },1000)
                    } else if (resp.data.status==500){
                        Toast.fail(resp.data.msg);
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>