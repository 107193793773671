<template>
  <div id="app">
    <router-view/>
      <tab-bar v-show="$route.meta.tabbarshow" >
        <tab-bar-item path="/user_repair" activeColor="aqua">
          <img slot="item-icon" src="~@/assets/img/tabbar/首页.svg" />
          <img slot="item-icon-active" src="~@/assets/img/tabbar/首页_active.svg" />
          <div slot="item-text">首页</div>
        </tab-bar-item>
        <van-badge :content="message">
          <tab-bar-item path="/user_message">
            <img slot="item-icon" src="~@/assets/img/tabbar/消息.svg" />
            <img slot="item-icon-active" src="~@/assets/img/tabbar/消息_active.svg" />
            <div slot="item-text">消息</div>
          </tab-bar-item>
        </van-badge>

        <tab-bar-item path="/my">
          <img slot="item-icon" src="~@/assets/img/tabbar/我的.svg" />
          <img slot="item-icon-active" src="~@/assets/img/tabbar/我的_active.svg" />
          <div slot="item-text">我的</div>
        </tab-bar-item>
      </tab-bar>

    <tab-bar v-show="$route.meta.tabbarshow2" >
      <tab-bar-item path="/RepairHome" activeColor="aqua">
        <img slot="item-icon" src="~@/assets/img/tabbar/首页.svg" />
        <img slot="item-icon-active" src="~@/assets/img/tabbar/首页_active.svg" />
        <div slot="item-text">首页</div>
      </tab-bar-item>
      <van-badge :content="message">
        <tab-bar-item path="/RepairMessage">
          <img slot="item-icon" src="~@/assets/img/tabbar/消息.svg" />
          <img slot="item-icon-active" src="~@/assets/img/tabbar/消息_active.svg" />
          <div slot="item-text">消息</div>
        </tab-bar-item>
      </van-badge>

      <tab-bar-item path="/RepairMy">
        <img slot="item-icon" src="~@/assets/img/tabbar/我的.svg" />
        <img slot="item-icon-active" src="~@/assets/img/tabbar/我的_active.svg" />
        <div slot="item-text">我的</div>
      </tab-bar-item>
    </tab-bar>
    </div>
</template>
<script>
// import HelloWorld from '@/components/HelloWorld.vue'
import TabBar from '@/components/tabbar/TabBar'
import TabBarItem from '@/components/tabbar/TabBarItem'
import GLOBAL from '@/api/global_variable'
export default {
  name: 'app',
  metaInfo: {
    meta: [
      {
        name: 'referrer',
        content: 'no-referrer'
      },
    ]
  },
  components: {
    // HelloWorld
    TabBar,
    TabBarItem
  },
  data(){
    return{
      role:'',
      message:'',
      userSite: GLOBAL.userAgent
    }
  },
  created() {
    var _this = this;
    let token =  sessionStorage.getItem("token");
    axios.defaults.headers.common["satoken"] =  token;
    axios.get('http://43.139.145.191:8081/user/countUnreadMessage').then(function (resp) {
          if (resp.data.data!=0)
          _this.message = resp.data.data
    });
    setInterval(function(){
      axios.get('http://43.139.145.191:8081/user/countUnreadMessage').then(function (resp) {
        if (resp.data.data!=0)
          _this.message = resp.data.data
      })
    },3000);
  },
  /* 路由监听 */
  watch:{
    $route(to,from){
      if ( (from.fullPath.includes("login")) ){
          location.reload();
      }
      if (from.fullPath.includes("essage")){
        axios.post('http://43.139.145.191:8081/user/allRead')
        location.reload();
      }
    }
  },
}
</script>

<style scoped>
#app {
  font-family: "微軟正黑體 Light";
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
