import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Welcome from "../views/Welcome";
import Login from "../views/Login";
import Register from "../views/Register";
import Index from "../views/Index";
import UserRepair from "../views/UserRepair";
import My from "../views/My"
import UserMessage from "../views/UserMessage";
import UserInfo from "../views/UserInfo";
import RepairOrder from "../views/RepairOrder";
import UserEvaluate from "../views/UserEvaluate";
import Feedback from "../views/Feedback";
import AddressNew from "../views/AddressNew";
import AddressEdit from "../views/AddressEdit";
import Test from "../views/test"
import RepairHome from "../views/RepairHome";
import RepairMessage from "../views/RepairMessage";
import OrderStep from "../views/OrderStep";
import RepairMy from "../views/RepairMy";
import UserOrder from "../views/UserOrder";
import AdminHome from "../views/AdminHome";
import AdminOrder from "../views/AdminOrder";
import AdminFindUser from "../views/AdminFindUser";
import AdminRepairType from "../views/AdminRepairType";
import AdminComplain from "../views/AdminComplain";
import OrderInfo from "../views/OrderInfo";
import App from "../App";
import ComplaintInfo from "../views/ComplaintInfo";
import UserInfoEdit from "../views/UserInfoEdit";
import FeedBackManagement from "../views/FeedBackManagement";

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/",
    name: 'Welcome',
    component: Welcome,
    meta:{
      title:'欢迎页',
      tabbarshow:false
    }
  },{
    path: "/login",
    name: "login",
    component: Login,
    meta:{
      title:'登录页',
      tabbarshow:false
    }
  },{
    path: "/register",
    name: "注册",
    component: Register,
  },{
    path:"/index",
    name: "首页",
    component: Index,
    meta:{
      tabbarshow:true
    }
  },{
    path: "/user_repair",
    name: "维修单提交",
    component: UserRepair,
    meta:{
      tabbarshow:true
    }
  },{
    path: "/my",
    name: "个人中心",
    component: My,
    meta:{
      tabbarshow:true
    }
  },{
    path: "/user_message",
    name: "消息中心",
    component: UserMessage,
    meta:{
      tabbarshow:true
    }
  },{
    path: "/UserInfoEdit",
    name: "个人信息",
    component: UserInfoEdit,
  },{
    path: "/UserOrder",
    name: "报修订单",
    component: UserOrder,
  },{
    path: "/UserEvaluate",
    name: "用户报修评价",
    component: UserEvaluate,
  },{
    path: "/UserFeedback",
    name: "意见反馈",
    component: Feedback,
  },{
    path: "/addressNew",
    name: "添加地址",
    component:AddressNew
  },{
    path: '/addressEdit',
    name: '修改地址',
    component: AddressEdit
  },{
    path: '/test',
    name: '测试页',
    component: Test
  },{
    path: '/RepairHome',
    name: '维修员首页',
    component: RepairHome,
    meta:{
      tabbarshow2:true
    }
  },{
    path: '/RepairMessage',
    name: '维修员消息',
    component: RepairMessage,
    meta:{
      tabbarshow2:true
    }
  },{
    path: "/OrderStep",
    name: '订单任务信息页',
    component: OrderStep
  },{
    path: '/RepairMy',
    name: '维修个人中心',
    component: RepairMy,
    meta:{
      tabbarshow2:true
    }
  },{
    path: "/RepairOrder",
    name: '维修员订单管理',
    component: RepairOrder
  },{
      path: "/AdminHome",
    name: "管理员页",
    component: AdminHome
  },{
    path: "/AdminOrder",
    name: "审核订单",
    component: AdminOrder
  },{
    path: "/AdminFindUser",
    name: "查看用户类型",
    component: AdminFindUser
  },{
    path: "/AdminRepairType",
    name: "管理维修种类",
    component: AdminRepairType
  },{
    path: "/AdminComplain",
    name: "投诉管理",
    component: AdminComplain
  },{
    path: "/OrderInfo",
    component: OrderInfo
  },{
  path: "/app",
    component:App
  },{
  path: "/ComplaintInfo",
    component:ComplaintInfo
  },{
  path: "/FeedBackManagement",
    component:FeedBackManagement
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
