<template>
    <div>
        <van-nav-bar
                class="icon-back"
                title="审核订单"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-card
                    style="font-size: 15px; font-weight: bold"
                    v-for="(item,index) in list"
                    centered
                    :desc="address[index]"
                    :thumb="img[index]"
                    :title="problem[index]"
            >
                <template #tags>
                    <van-tag plain type="danger" >
                        {{item.repairType}}
                    </van-tag>
                </template>
                <template #footer>
                    <van-button plain round type="primary" size="small" @click="check(id[index])">查看</van-button>
                    <van-button round type="info" size="small" @click="buy(id[index])">通过</van-button>
                    <van-button color="red" round type="info" size="small" @click="returnOrder(id[index])">打回</van-button>
                </template>
            </van-card>
        </van-pull-refresh>
    </div>
</template>

<script>
    import AreaList from '../api/area';
    import {Toast} from "vant";
    import { Dialog } from 'vant';
    import items from "../api/items";
    export default {
        name: "AdminOrder",
        created() {

            let promiseArr = [];
            let promiseArr2 = [];
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/admin/getPendingOrders?page=1&pageSize=20').then((resp) => {
                //console.log(resp.data.data);
                this.list = resp.data.data;
               // console.log(this.problem);
               console.log(this.list);
            })


            let p = new Promise((resolve, reject) => {
                //当第三方api提供的是异步方法时 这里用setTimeout模拟
                setTimeout(() => {
                    //console.info('setTimeout');

                    //结束任务
                    resolve();
                }, 500)
            })
            promiseArr.push(p)


            Promise.all(promiseArr).then(res => {
                console.log(this.list.length)


                for (let i=0; i<this.list.length; i++){

                   let y =  new Promise((resolve, reject) => {
                        //当第三方api提供的是异步方法时 这里用setTimeout模拟
                        setTimeout(() => {
                            //console.info('setTimeout');
                            axios.get('http://43.139.145.191:8081/user/getOrderById?id='+this.list[i].id).then((response) =>{
                                //console.log(response.data.data)
                                this.problem.push(response.data.data.order.problem);
                                if (response.data.data.imgPaths[0].downloadUrl!=null)
                                    this.img.push(response.data.data.imgPaths[0].downloadUrl);
                                else
                                    this.img.push('https://img01.yzcdn.cn/vant/ipad.jpeg');

                                let x = "";

                                let ads = "";//存详细地址
                                x = response.data.data.order.province;
                                x+="0000";//省

                                ads += AreaList.province_list[x];
                                x = response.data.data.order.province+response.data.data.order.city;
                                x+="00";//市

                                if (ads!=AreaList.city_list[x])//如果省和市不相同
                                    ads += AreaList.city_list[x];
                                x = response.data.data.order.province+response.data.data.order.city+response.data.data.order.area;//区&县

                                ads += AreaList.county_list[x];
                                let detail = response.data.data.order.address.toString();
                                let change = detail.split(",");
                                ads += change[2].substring(6);//完整地址
                                this.address.push(ads);
                            })
                            //结束任务
                            resolve();
                        }, 500)
                    })
                    promiseArr2.push(y)
                    Promise.all(promiseArr2).then(res => {

                        this.id[i] = this.list[i].id;
                    })

                }

            })
        },
        data() {
            return {
                show: false,
                id:[],
                list:[],
                img:[],
                problem:[],
                address:[],
                phones: [
                    {
                        desc:'电脑黑屏一直自己重启',
                        address:'湖南省长沙市',
                        thumb:'https://img01.yzcdn.cn/vant/ipad.jpeg',
                        tag:{
                            name: '电脑设备'
                        }
                    }
                ],
                error: false,
                loading: false,
                finished: false,
                refreshing: false,
            };
        },
        methods: {
            check(id){
                this.show = true;
                let data = JSON.stringify(id)
                this.$router.push({path:'/OrderInfo',query:{id:data}})
            },
            returnOrder(index){
                Dialog.confirm({
                    title: '确认通知',
                    message: '确定打回吗',
                }).then(() => {
                        //Toast(index)
                        let _this = this;
                        axios.post('http://43.139.145.191:8081/admin/pendOrderById',{
                            id:index,
                            pend:0
                        }).then(function (response) {
                            if (response.data.status==200){
                                Toast.success("退回成功")
                                setTimeout(() =>{
                                    location.reload();
                                },500)
                            }
                        })
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            tolast(){
              this.$router.go(-1)
            },
            showPopup() {
                this.show = true;
            },
            onLoad() {
                // 异步更新数据
                setTimeout(() => {
                    if (this.refreshing) {
                        this.refreshing = false;
                    }
                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成

                    this.finished = true;

                }, 1000);
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            buy(index){
                Dialog.confirm({
                    title: '确认通知',
                    message: '确定通过吗',
                }).then(() => {
                        //Toast(index)
                        let _this = this;
                        axios.post('http://43.139.145.191:8081/admin/pendOrderById',{
                            id:index,
                            pend:true
                        }).then(function (response) {
                            if (response.data.status==200){
                                Toast.success("订单已通过")
                                setTimeout(()=>{
                                    window.location.reload();
                                },300)
                            }
                        })
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            navClick(index){
                Toast(items.areas[index].areaCode);
            }
        },
    }
</script>

<style scoped>

</style>