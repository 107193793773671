<template>
    <div>
        <div class="demo-nav__title message">个人中心</div>
        <van-row type="flex">
            <van-col span="7">
                <van-image
                        round
                        width="6rem"
                        height="6rem"
                        :src="head"
                />
            </van-col>
            <van-col span="4"><h2 id="username"><span v-text="username"></span></h2></van-col>
        </van-row>
        <i class="cubeic-arrow"></i>
        <ul>
            <li v-for="item in minearry" class="mineitem demo-home-nav__block"

                @click="itemclick(item)" :key="item.label">

                <van-icon :name="item.icon" size="26px"/>

                <span class="minetitle">{{item.label}}
                    <van-icon class="van-icon-arrow" style="position: relative;left: 160px"></van-icon>
                </span>
                <i class="cubeic-arrow"></i>
            </li>
        </ul>
    </div>
</template>
<script>

    export default {

        created() {
            let _this = this;
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/user/getUserInfo').then(function (resp) {
                _this.username = resp.data.data.user.account;
                _this.head = resp.data.data.avatarPath.downloadUrl;
                console.log(_this.head)
            })
        },

        data(){

            return{
                username:'',

                head:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fi1.hdslb.com%2Fbfs%2Fface%2F401a6fb2434029d8d9e1332d069775300b2e1320.jpg&refer=http%3A%2F%2Fi1.hdslb.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639715927&t=b2146fbc0336127396421d5aed978b86',

                minearry:[

                    {label:'个人信息',icon:'user-o',type: 'UserInfoEdit'},

                    {label:'接手订单',icon:'orders-o',type: 'RepairOrder'},

                    {label:'意见反馈',icon:'envelop-o',type: 'UserFeedback'},

                    {label:'关于软件',icon:'bullhorn-o',type: 'About'},

                    {label:'退出登录',type:'exit',icon: 'revoke'},

                ]

            }

        },
        methods:{

            itemclick(item){

                if(item.type==='exit'){

                    this.$store.commit('settoken','')

                    sessionStorage.removeItem('token');

                    let _this = this;
                    axios.post('http://43.139.145.191:8081/user/logout').then(function (resp) {
                        if (resp.data.status==200){
                            _this.$router.push('/login')
                        }
                    })

                }else if(item.type==='UserInfoEdit'){
                    this.$router.push({path:'/'+item.type})
                }else if(item.type==='RepairOrder'){
                    this.$router.push({path:'/'+item.type})
                }else if(item.type==='UserEvaluate'){
                    this.$router.push({path:'/'+item.type})
                }else if(item.type==='UserFeedback'){
                    this.$router.push({path:'/'+item.type})
                }else if(item.type==='About'){
                    this.$router.push({path:'/'+item.type})
                }
            }
        }
    }

</script>

<style  scoped>

    .mineitem{
        font-family: "微軟正黑體 Light";

        font-weight: bold;

        margin-top: 40px;

        font-size:  20px;

        text-align: left;

        height:  50px;

        line-height: 50px;

        padding-left: 5%;

        border-bottom: 1px solid #eee;
    }

    /*.minetitle{*/
    /*    display:inline-block;*/
    /*    width:85%;*/
    /*}*/

    .demo-nav__title {
        font-weight: 600;
        font-size: 25px;
        text-transform: capitalize;
        display: block;
    }
    .demo-home-nav__block {
        margin: 30px 23px 30px;
        padding-left: 20px;
        color: #323233;
        font-weight: 600;
        font-size: 20px;
        line-height: 50px;
        /*background: #f1f2f6;*/
        border-radius: 99px;
        -webkit-transition: background 0.3s;
        transition: background 0.3s;
    }
</style>
