import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant.js'
import Vant from 'vant'
import 'vant/lib/index.less';
import { Badge } from 'vant';
import './assets/css/css.css';


// // 全局注册
// Vue.use(Dialog);

Vue.use(Badge);

Vue.config.productionTip = false
Vue.use(Vant)


import Meta from 'vue-meta';
Vue.use(Meta);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//设置
Vue.prototype.Setcookie = function (name,value) {
  //设置名称为name,值为value的Cookie
  var expdate = new Date();   //初始化时间
  expdate.setTime(expdate.getTime() + 30 * 60 * 1000);   //时间单位毫秒
  document.cookie = name + "=" + escape(value) + ";expires=" + expdate.toGMTString() + ";path=/";
  //即document.cookie= name+"="+value+";path=/";  时间默认为当前会话可以不要，但路径要填写，因为JS的默认路径是当前页，如果不填，此cookie只在当前页面生效！
}

//获取
Vue.prototype.getcookie = function (a){
  // console.log(a)
  var d;
  var b = document.cookie;
  // console.log(b)
  var c = b.split(";");
  for (let e = 0; e < c.length; e++) {
    var f = c[e].split("=");
    if (a == f[0].toString().trim()) {
      d = f[1];
      break;
    }
  } if (void 0 == d || null == d) {
    return "";
  }
  else {
    var g = unescape(d.trim());
    return g;
  }
}

//删除
Vue.prototype.delCookie= function (a){
  var b = new Date(0).toGMTString();
  document.cookie = a + "=;expires=" + b + ";path=/";
}

