<template>
    <div>
        <div class="demo-nav__title icon-back">订单中心</div>

            <van-cell is-link @click="showPopup">
                筛选省份
            </van-cell>
            <van-popup round v-model="show"  position="bottom" :style="{ height: '30%'}">
                <van-area title="选择城市"
                          :columns-placeholder="['全国', '全省', '全市']"
                          :area-list="areaList"
                          @confirm="confirm"
                          @cancel="cancel"/>
            </van-popup>

        <van-cell is-link @click="showPopup2">
            筛选种类
        </van-cell>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="subjects"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
            />
        </van-popup>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-card
                    style="font-size: 15px; font-weight: bold"
                    v-for="(item,index) in list"
                    centered
                    :desc="address[index]"
                    :thumb="img[index]"
                    :title="problem[index]"
            >
                <template #tags>
                    <van-tag plain type="danger" >
                        {{item.repairType}}
                    </van-tag>
                </template>
                <template #footer>
                    <van-button plain round type="primary" size="small" @click="check(id[index])">查看</van-button>
                    <van-button round type="info" size="small" @click="buy(id[index])">接单</van-button>
                </template>
            </van-card>
        </van-pull-refresh>
    </div>
</template>

<script>
    import AreaList from "../api/area";
    import area from "../api/area";
    import {Dialog, Toast} from "vant";
    import items from "../api/items";

    export default {
        name: "RepairHome",
        created() {

            let promiseArr = [];
            let promiseArr2 = [];
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/repairman/getAllOrder?page=1&pageSize=20').then((resp) => {
                this.list = resp.data.data;
            })


            let p = new Promise((resolve, reject) => {
                //当第三方api提供的是异步方法时 这里用setTimeout模拟
                setTimeout(() => {
                    //结束任务
                    resolve();
                }, 500)
            })
            promiseArr.push(p)


            Promise.all(promiseArr).then(res => {


                for (let i=0; i<this.list.length; i++){

                    let y =  new Promise((resolve, reject) => {
                        //当第三方api提供的是异步方法时 这里用setTimeout模拟
                        setTimeout(() => {
                            //console.info('setTimeout');
                            axios.get('http://43.139.145.191:8081/user/getOrderById?id='+this.list[i].id).then((response) =>{
                                this.problem.push(response.data.data.order.problem);
                                if (response.data.data.imgPaths[0].downloadUrl!=null)
                                    this.img.push(response.data.data.imgPaths[0].downloadUrl);
                                else
                                    this.img.push('https://img01.yzcdn.cn/vant/ipad.jpeg');

                                let x = "";

                                let ads = "";//存详细地址
                                x = response.data.data.order.province;
                                x+="0000";//省

                                ads += AreaList.province_list[x];
                                x = response.data.data.order.province+response.data.data.order.city;
                                x+="00";//市

                                if (ads!=AreaList.city_list[x])//如果省和市不相同
                                    ads += AreaList.city_list[x];
                                x = response.data.data.order.province+response.data.data.order.city+response.data.data.order.area;//区&县

                                ads += AreaList.county_list[x];
                                let detail = response.data.data.order.address.toString();
                                let change = detail.split(",");
                                ads += change[2].substring(6);//完整地址
                                this.address.push(ads);
                            })
                            //结束任务
                            resolve();
                        }, 500)
                    })
                    promiseArr2.push(y)
                    Promise.all(promiseArr2).then(res => {
                        this.id[i] = this.list[i].id;
                        console.log(this.id)
                    })

                }

            })
            let _this = this;
            axios.get('http://43.139.145.191:8081/user/getAllRepairType?page=1&pageSize=20').then(function (resp) {
                let subject = ['全部'];
                let subjectsId = []
                for (let i=0 ; i<resp.data.data.length ;i++){
                    subjectsId.push(resp.data.data[i].id);
                    subject.push(resp.data.data[i].name);
                }
                _this.subjectsId = subjectsId;
                _this.subjects = subject
            });
            setInterval(function(){
                axios.get('http://43.139.145.191:8081/user/countUnreadMessage').then(function (resp) {
                    if (resp.data.data!=0)
                        _this.message = resp.data.data
                })
            },3000);
        },
        data() {
            return {
                repairTypeId:null,
                showPicker:false,
                areaList: area,
                items:
                items.areas
            ,
                activeId: 1,
                activeIndex: 0,
                show: false,
                id:[],
                list:[],
                img:[],
                problem:[],
                address:[],
                subjects:[],
                subjectsId:[],
                valueArea: '', //地区值
                arrArea: [], //存放地区数组

                phones: [
                    {
                        desc:'电脑黑屏一直自己重启',
                        address:'湖南省长沙市',
                        thumb:'https://img01.yzcdn.cn/vant/ipad.jpeg',
                        tag:{
                            name: '电脑设备'
                        }
                    }
                ],
                error: false,
                loading: false,
                finished: false,
                refreshing: false,
            };
        },
        methods: {
            check(id){
                let data = JSON.stringify(id)
                this.$router.push({path:'/OrderInfo',query:{id:data}})
            },
            cancel(){
                this.show = false;
            },
            onConfirm(value,index){
                let promiseArr = [];
                let promiseArr2 = [];
                let code;
                this.repairTypeId = this.subjects[index];//取名称
                if (typeof(this.arrArea[2]) != "undefined" && this.arrArea[2].code!='')     //区
                    code = this.arrArea[2].code.valueOf();
                else if(typeof(this.arrArea[1]) != "undefined" && this.arrArea[1].code!='')     //市
                    code = this.arrArea[1].code.valueOf();
                else
                    code = this.arrArea[0].code.valueOf();
                if (value!=='全部'){
                    axios.get('http://43.139.145.191:8081/repairman/getAllOrderByRequirements?page=1&pageSize=20&areaCode='+code+'&repairType='+this.repairTypeId).then((resp) => {
                        this.list = resp.data.data;
                        if (resp.data.status===200){
                            //this.onLoad();
                            for (let i=0; i<this.list.length; i++){
                                //console.info('setTimeout');
                                axios.get('http://43.139.145.191:8081/user/getOrderById?id='+this.list[i].id).then((response) =>{
                                    //console.log(response.data.data)
                                    this.problem.push(response.data.data.order.problem);
                                    if (response.data.data.imgPaths[0].downloadUrl!=null)
                                        this.img.push(response.data.data.imgPaths[0].downloadUrl);
                                    else
                                        this.img.push('https://img01.yzcdn.cn/vant/ipad.jpeg');

                                    let x = "";

                                    let ads = "";//存详细地址
                                    x = response.data.data.order.province;
                                    x+="0000";//省

                                    ads += AreaList.province_list[x];
                                    x = response.data.data.order.province+response.data.data.order.city;
                                    x+="00";//市

                                    if (ads!=AreaList.city_list[x])//如果省和市不相同
                                        ads += AreaList.city_list[x];
                                    x = response.data.data.order.province+response.data.data.order.city+response.data.data.order.area;//区&县

                                    ads += AreaList.county_list[x];
                                    let detail = response.data.data.order.address.toString();
                                    let change = detail.split(",");
                                    ads += change[2].substring(6);//完整地址
                                    this.address.push(ads);
                                    this.id[i] = this.list[i].id;
                                })

                            }
                        }
                    })
                } else {
                    axios.get('http://43.139.145.191:8081/repairman/getAllOrder?page=1&pageSize=20').then((resp) => {
                        this.list = resp.data.data;
                    })


                    let p = new Promise((resolve, reject) => {
                        //当第三方api提供的是异步方法时 这里用setTimeout模拟
                        setTimeout(() => {
                            //结束任务
                            resolve();
                        }, 500)
                    })
                    promiseArr.push(p)


                    Promise.all(promiseArr).then(res => {


                        for (let i=0; i<this.list.length; i++){

                            let y =  new Promise((resolve, reject) => {
                                //当第三方api提供的是异步方法时 这里用setTimeout模拟
                                setTimeout(() => {
                                    //console.info('setTimeout');
                                    axios.get('http://43.139.145.191:8081/user/getOrderById?id='+this.list[i].id).then((response) =>{
                                        this.problem.push(response.data.data.order.problem);
                                        if (response.data.data.imgPaths[0].downloadUrl!=null)
                                            this.img.push(response.data.data.imgPaths[0].downloadUrl);
                                        else
                                            this.img.push('https://img01.yzcdn.cn/vant/ipad.jpeg');

                                        let x = "";

                                        let ads = "";//存详细地址
                                        x = response.data.data.order.province;
                                        x+="0000";//省

                                        ads += AreaList.province_list[x];
                                        x = response.data.data.order.province+response.data.data.order.city;
                                        x+="00";//市

                                        if (ads!=AreaList.city_list[x])//如果省和市不相同
                                            ads += AreaList.city_list[x];
                                        x = response.data.data.order.province+response.data.data.order.city+response.data.data.order.area;//区&县

                                        ads += AreaList.county_list[x];
                                        let detail = response.data.data.order.address.toString();
                                        let change = detail.split(",");
                                        ads += change[2].substring(6);//完整地址
                                        this.address.push(ads);
                                    })
                                    //结束任务
                                    resolve();
                                }, 500)
                            })
                            promiseArr2.push(y)
                            Promise.all(promiseArr2).then(res => {
                                this.id[i] = this.list[i].id;
                                console.log(this.id)
                            })

                        }

                    })
                }
                this.showPicker = false;
            },
            confirm(val){
                this.show = false;
                this.arrArea = val;
                // this.valueArea = val[0].name + '-' + val[1].name + '-' + val[2].name;
                this.list = [];
                this.id =[];
                this.img = [];
                this.problem = [];
                this.address = [];
                let code;
                console.log(this.arrArea);
                if (typeof(this.arrArea[2]) != "undefined" && this.arrArea[2].code!='')     //区
                    code = this.arrArea[2].code.valueOf();
                else if(typeof(this.arrArea[1]) != "undefined" && this.arrArea[1].code!='')     //市
                    code = this.arrArea[1].code.valueOf();
                else
                    code = this.arrArea[0].code.valueOf();
                axios.get('http://43.139.145.191:8081/repairman/getAllOrderByRequirements?page=1&pageSize=20&areaCode='+code+'&repairType='+this.repairTypeId).then((resp) => {
                    this.list = resp.data.data;
                    if (resp.data.status===200){
                        //this.onLoad();
                        for (let i=0; i<this.list.length; i++){
                                    //console.info('setTimeout');
                            axios.get('http://43.139.145.191:8081/user/getOrderById?id='+this.list[i].id).then((response) =>{
                                //console.log(response.data.data)
                                this.problem.push(response.data.data.order.problem);
                                if (response.data.data.imgPaths[0].downloadUrl!=null)
                                    this.img.push(response.data.data.imgPaths[0].downloadUrl);
                                else
                                    this.img.push('https://img01.yzcdn.cn/vant/ipad.jpeg');

                                let x = "";

                                let ads = "";//存详细地址
                                x = response.data.data.order.province;
                                x+="0000";//省

                                ads += AreaList.province_list[x];
                                x = response.data.data.order.province+response.data.data.order.city;
                                x+="00";//市

                                if (ads!=AreaList.city_list[x])//如果省和市不相同
                                    ads += AreaList.city_list[x];
                                x = response.data.data.order.province+response.data.data.order.city+response.data.data.order.area;//区&县

                                ads += AreaList.county_list[x];
                                let detail = response.data.data.order.address.toString();
                                let change = detail.split(",");
                                ads += change[2].substring(6);//完整地址
                                this.address.push(ads);
                                this.id[i] = this.list[i].id;
                            })

                        }
                    }
                })
            },
            showPopup2() {
                this.showPicker = true;
            },
            showPopup() {
                this.show = true;
            },
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                setTimeout(() => {
                    if (this.refreshing) {
                        //this.list = [];
                        this.refreshing = false;
                    }
                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    this.finished = true;

                }, 1000);
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            buy(id){
                Dialog.confirm({
                    title: '确认通知',
                    message: '确定接单吗',
                })
                    .then(() => {
                        axios.post('http://43.139.145.191:8081/repairman/pickupOrder',{orderId:id,pick:true}).then(function (resp) {
                            //_this.list = resp.data.data;
                            if (resp.data.status==200){
                                Toast.success("接单成功");
                                window.location.reload();
                            }
                        })
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
                // axios.get('http://localhost:8010/phone/findSpecsByPhoneId/'+this.phones[index].id).then(function (resp) {
                //     _this.goods = resp.data.data.goods
                //     _this.sku = resp.data.data.sku
                // })
            },
            navClick(index){
                Toast(items.areas[index].areaCode);
            }
        },
    }
</script>

<style scoped>

</style>