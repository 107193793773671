<template>
    <div>
    </div>
</template>

<script>
    import GLOBAL from '@/api/global_variable'
    export default {
        name: "Index",
        created() {
            console.log(GLOBAL.userSite);
        },
        data() {
            return {
                active: 0,
                icon: {
                    active: 'https://img01.yzcdn.cn/vant/user-active.png',
                    inactive: 'https://img01.yzcdn.cn/vant/user-inactive.png',
                },
            };
        },

    }
</script>

<style scoped>

</style>