<template>

    <div>
        <van-image class="van-image__img" :src="require('../img/FM.png')" style="background-repeat: repeat" @click="onLogin"/>
<!--        <van-button class="button" round type="info" style="position: absolute;left: 78px;top: 750px" @click="onLogin">立即登录开始你的维修</van-button>-->
    </div>

</template>

<script>
    export default {
        name: "Welcome",
        methods:{
            onLogin() {
                this.$router.push('/login')
            }
        }
    };
</script>

<style scoped>
    .button{
        width: 300px;
        height: 40px;
    }
    .van-image__img {
        object-fit: fill !important;
        position: absolute;
        top: 0px;
    }

</style>