export default {
    areas:[
        {
            text:'北京市',
            areaCode:110000
            ,children:[]
        },
        {
            text:'天津市',
            areaCode:120000
            ,children:[]
        },
        {
            text:'河北省',
            areaCode:130000
            ,children:[]
        },
        {
            text:'山西省',
            areaCode:140000
            ,children:[]
        },
        {
            text:'内蒙古自治区',
            areaCode:150000
            ,children:[]
        },
        {
            text:'辽宁省',
            areaCode:210000
            ,children:[]
        },
        {
            text:'吉林省',
            areaCode:220000
            ,children:[]
        },
        {
            text:'黑龙江省',
            areaCode:230000
            ,children:[]
        },
        {
            text:'上海市',
            areaCode:310000
            ,children:[]
        },
        {
            text:'江苏省',
            areaCode:320000
            ,children:[]
        },
        {
            text:'浙江省',
            areaCode:330000
            ,children:[]
        },
        {
            text:'安徽省',
            areaCode:340000
            ,children:[]
        },
        {
            text:'福建省',
            areaCode:350000
            ,children:[]
        },
        {
            text:'江西省',
            areaCode:360000
            ,children:[]
        },
        {
            text:'山东省',
            areaCode:370000
            ,children:[]
        },
        {
            text:'河南省',
            areaCode:410000
            ,children:[]
        },
        {
            text:'湖北省',
            areaCode:420000
            ,children:[]
        },
        {
            text:'湖南省',
            areaCode:430000
            ,children:[]
        },
        {
            text:'广东省',
            areaCode:440000
            ,children:[]
        },
        {
            text:'广西壮族自治区',
            areaCode:450000
            ,children:[]
        },
        {
            text:'海南省',
            areaCode:460000
            ,children:[]
        },
        {
            text:'重庆市',
            areaCode:500000
            ,children:[]
        },
        {
            text:'四川省',
            areaCode:510000
            ,children:[]
        },
        {
            text:'贵州省',
            areaCode:520000
            ,children:[]
        },
        {
            text:'云南省',
            areaCode:530000
            ,children:[]
        },
        {
            text:'西藏自治区',
            areaCode:540000
            ,children:[]
        },
        {
            text:'陕西省',
            areaCode:610000
            ,children:[]
        },
        {
            text:'甘肃省',
            areaCode:620000
            ,children:[]
        },
        {
            text:'青海省',
            areaCode:630000
            ,children:[]
        },
        {
            text:'宁夏回族自治区',
            areaCode:640000
            ,children:[]
        },
        {
            text:'新疆维吾尔自治区',
            areaCode:650000
            ,children:[]
        }
    ]
}