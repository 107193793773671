<template>
    <div class="message">
        <van-notice-bar v-if="message!=0" mode="closeable">您有新的消息，请注意查看。</van-notice-bar>
        <div class="demo-nav__title">维修申请</div>
        <van-form @submit="onSubmit" >
            <van-cell style="margin-top: 50px" class="left-select" is-link title="选择地址" @click="show = true" :value="address" />
<!--            <van-field-->
<!--                    class="mineitem"-->
<!--                    v-model="equipment"-->
<!--                    type="text"-->
<!--                    name="设备名称"-->
<!--                    label="设备名称"-->
<!--                    placeholder="请输入设备名称"-->
<!--                    :rules="[{ required: true, message: '请填写设备名称' }]"-->
<!--            />-->
            <van-field
                    class="mineitem"
                    v-model="problem"
                    type="text"
                    name="problem"
                    label="描述"
                    placeholder="请输入描述"
                    :rules="[{ required: true, message: '请输入描述' }]"
            />

            <van-field name="uploader" label="上传维修图" class="mineitem" style="height: 100px">
                <template #input>
                    <van-uploader v-model="uploader" />
                </template>
            </van-field>

            <van-field
                    class="mineitem"
                    readonly
                    clickable
                    name="subject"
                    :value="subject"
                    label="指定维修种类"
                    placeholder="点击选择"
                    @click="showPicker2 = true"
            />
            <van-popup v-model="showPicker2" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="subjects"
                        @confirm="onConfirm"
                        @cancel="showPicker2 = false"
                />
            </van-popup>
            <van-field
                    class="mineitem"
                    readonly
                    clickable
                    name="picker"
                    :value="value"
                    label="指定维修师傅"
                    placeholder="点击选择"
                    @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="columns"
                        @confirm="onConfirm_p"
                        @cancel="showPicker = false"
                />
            </van-popup>


            <label hidden>
                <van-field name="repairTypeId" :value="repairTypeId"></van-field>
                <van-field v-model="repairmanId" name="repairmanId"></van-field>
                <van-field v-model="areaCode" name="areaCode"></van-field>
                <van-field v-model="addressId" name="addressId" label="文本" border hidden="hidden" />
                <van-field v-model="address" name="address" label="文本" border hidden="hidden" />
                <van-field
                        v-model="phone"
                        type="text"
                        name="phone"
                />
                <van-field
                        v-model="username"
                        name="customName"
                        label="报修人姓名"
                />
            </label>

            <van-action-sheet v-model="show" title="维修地址">
                <div class="content">
                    <van-address-list
                            v-model="chosenAddressId"
                            :list="list"
                            default-tag-text="默认"
                            @click-item="onClick"
                            @add="onAdd"
                            @edit="onEdit"
                    />
                </div>
            </van-action-sheet>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" class="mineitem" style="margin-top: 25px;margin-bottom: 80px">提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    import AreaList from "../api/area";
    export default {
        name: "UserRepair",
        created() {
            let _this = this;
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/custom/getAllAddress?page=1&pageSize=20').then(function (resp) {
                if (resp.data.status == 200){
                    _this.list = resp.data.data;
                      let x = "";
                      for (let i=0; i<_this.list.length ;i++){
                          let ads = "";//存详细地址
                          x = _this.list[i].areaCode.toString().substring(0,2);
                          x+="0000";//省

                          ads += AreaList.province_list[x];
                          x = _this.list[i].areaCode.toString().substring(0,4);
                          x+="00";//市

                          if (ads!=AreaList.city_list[x])//如果省和市不相同
                          ads += AreaList.city_list[x];
                          x = _this.list[i].areaCode.toString();//区&县

                          ads += AreaList.county_list[x];
                          ads += resp.data.data[i].detail;//完整地址
                          _this.list[i].address = ads;
                          if (resp.data.data[i].isDefault){
                              _this.chosenAddressId = resp.data.data[i].id;
                              _this.address = ads;
                              _this.addressId = resp.data.data[i].id;
                          }
                    }
                    // console.log(_this.list);
                }
            });
            axios.get('http://43.139.145.191:8081/user/getAllRepairType?page=1&pageSize=20').then(function (resp) {
                    let subject = [];
                    let subjectsId = []
                    for (let i=0 ; i<resp.data.data.length ;i++){
                        subjectsId.push(resp.data.data[i].id);
                        subject.push(resp.data.data[i].name);
                    }
                _this.subjectsId = subjectsId;
                 _this.subjects = subject
            });
            axios.get('http://43.139.145.191:8081/user/countUnreadMessage').then(function (resp) {
                _this.message = resp.data.data
            });

            setInterval(function(){
                axios.get('http://43.139.145.191:8081/user/countUnreadMessage').then(function (resp) {
                    if (resp.data.data!=0)
                        _this.message = resp.data.data
                })
            },3000);
        },
        data() {
            return {
                message:'',
                repairmanId: null,
                addressId:'1',
                subject: '',
                repairTypeId: '',
                areaCode:'',
                address:'',
                show: false,
                actions: [{ name: '选项一' }, { name: '选项二' }, { name: '选项三' }],
                value: '',
                username: '张三',
                phone: '13000000000',
                equipment: '',
                uploader: [],
                problem: '',
                addressInfo: '',
                repairmanList:[],
                columns: [],
                subjects: [],
                subjectsId: [],
                // subjects:[
                //              {id:1,name:'电脑设备'}
                //             ,{id:2,name:'电气设备'}
                //             ,{id:3,name:'桌椅设备'}
                //         ],
                showPicker: false,
                showPicker2: false,
                chosenAddressId: '1',
                list: [

                ],
            };
        },
        methods: {
            onClick(item,index) {
                this.address=this.list[index].address;
                this.areaCode=this.list[index].areaCode;
                this.phone=this.list[index].tel;
                this.username=this.list[index].name;
                this.addressId=this.list[index].id;
            },

            onAdd() {
                this.$router.push('/addressNew')
            },

            onEdit(item, index) {
                let data = JSON.stringify(item)
                this.$router.push({path:'/addressEdit',query:{item:data}})
                // Toast('编辑地址:' + index);
            },

            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                Toast(item.name);
            },

            onConfirm(value,index) {
                this.subject = value;
                this.repairTypeId = this.subjectsId[index];
                let _this = this;
                // Toast(`当前值：${value}, 当前索引：${index}`);
                axios.get('http://43.139.145.191:8081/custom/getRepairman?id='+this.repairTypeId).then(function (resp) {
                    _this.repairmanList = resp.data.data;
                    let columns = [];
                    for (let i =0 ;i<_this.repairmanList.length;i++){
                        columns.push(_this.repairmanList[i].name);
                    }
                    _this.value = '';
                    _this.columns = columns;
                });
                this.showPicker2 = false;
            },

            onConfirm_p(value,index) {
                this.repairmanId = this.repairmanList[index].id;
                this.value = value;
                // Toast(this.repairmanId);
                this.showPicker = false;
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器 content
                console.log(file);
                let imgstr = file.content.replace(/^data:image\/\w+;base64,/, '');
                axios({
                    method:'post',
                    url:'http://43.139.145.191:8081/custom/uploadFile',
                    headers: {'Content-Type':'application/json;charset=UTF-8'},
                    data:{
                        str:imgstr,
                        name:file.file.name
                    }
                })
            },

            onSubmit(values) {
                // console.log(values);
                let formdata = new FormData();
                if (values.repairmanId==""){
                    values.repairmanId = null;
                }
                formdata.append('json',"{\n" +
                    "\"repairTypeId\":" +values.repairTypeId+
                    ",\"addressId\":" +values.addressId+
                    ",\"repairmanId\":" +values.repairmanId+
                    ",\"problem\":\""+values.problem+"\"}");
                if (this.uploader.length > 0){
                    for (let i = 0; i < this.uploader.length; i++){
                        const element = this.uploader[i];
                        formdata.append("images",element.file);
                    }
                }
                // console.log(formdata.get("json"));
                axios.post('http://43.139.145.191:8081/custom/createOrder',formdata).then(function (resp) {
                    if (resp.data.status==200){
                        Toast.success("成功！");
                    }
                })
                // this.$dialog.alert({
                //     message: '弹窗内容',
                // });
                // console.log('submit', values);
            },
        },
    }
</script>

<style scoped>
    .mineitem{
        font-weight: bold;

        font-size: 18px;

        margin-top: 40px;
    }
    .demo-nav__title {
        font-weight: 600;
        font-size: 25px;
        text-transform: capitalize;
        display: block;
    }
    .content {
        padding: 16px 16px 160px;
    }
    .left-select{
        font-family: "微軟正黑體 Light";

        font-weight: bold;

        text-align: left;

        font-size:  18px;

        line-height: 20px;

        padding-left: 5%;

    }
</style>