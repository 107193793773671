<template>
    <div class="tab-bar-item"
    @click="itemClick">
        <slot v-if="!isActive" name="item-icon"></slot>
        <slot v-else name="item-icon-active"></slot>
        <div :class="{active: isActive}">
            <slot name="item-text"></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "TabBarItem",
        props:{
          path: String,
            activeColor:{
              type: String,
                default(){
                  return '#f40'
                }
            }
        },
        methods:{
            itemClick(){
                // console.log(this.$router)
                // console.log(this.path)
                this.$router.replace(this.path)
            }
        },
        computed:{
            isActive(){
                // return this.$route.path.indexOf(this.path) !== -1
                return this.$route.path.includes(this.path)
            },
            activeStyle(){
                return this.isActive() ? {color: this.activeColor} : {}
            }
        }
    }
</script>

<style scoped>
    .tab-bar-item {
        flex: 1;
        text-align: center;
        font-size: 17px;
    }

    .tab-bar-item img {
        width: 30px;
        height: 30px;
        margin-top: 5px;
        vertical-align: middle;
    }
    .active {
        color: #1296db;
    }
</style>