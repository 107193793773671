<template>
    <div style="background-color: rgb(247,248,250);height: 889px;text-align: left">
        <van-nav-bar
                class="icon-back"
                title="我的反馈"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <h2 class="van-doc-demo-block__title">全部反馈</h2>
                <van-cell-group v-for="(item,index) in list" inset>
                    <van-cell
                            center
                            :value=list[index].content.toString()
                            name="content"
                            title="内容"
                            label="投诉内容"
                            @click="Info(item.order.id)"
                    />
                    <van-cell
                            center
                            :value=list[index].createDate.toString()
                            name="time"
                            title="投诉时间"
                            @click="Info(item.order.id)"
                    />
                    <van-cell>
                        <van-button round type="danger" block size="small" @click="Delete(item.id)">撤销</van-button>
                    </van-cell>
                    <van-divider />
                    <van-divider />
                </van-cell-group>
                <!--            <van-cell class="mineitem" v-for="item in list" :key="item" :title="item" />-->
            </van-list>
        </van-pull-refresh>
    </div>

</template>

<script>
    import {Toast} from "vant";

    export default {
        created() {
            let _this = this;
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/custom/getHistoryComplaint?page=1&pageSize=20').then(function (resp) {
                _this.list = resp.data.data;
            })
        },
        data() {
            return {
                list:[],
                loading: false,
                finished: false,
                refreshing: false,
            };
        },
        methods:{
            Delete(id){
                axios.delete('http://43.139.145.191:8081/custom/delComplaint',{
                    data:{
                        id:parseInt(id)
                    }
                }).then((resp) =>{
                    if (resp.data.status==200){
                        Toast.success("撤销成功");
                        setTimeout(()=>{
                            location.reload();
                        },500)
                    }
                })
            },
            Info(orderId){
                this.$router.push({path:'/OrderInfo',query:{id:orderId}})
            },
            tolast(){
                this.$router.go(-1);
            },

            onLoad() {
                // 异步更新数据

                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                // 数据全部加载完成
                let _this = this
                axios.get('http://43.139.145.191:8081/custom/getHistoryComplaint?page=1&pageSize=20').then(function (resp) {
                    _this.list = [];
                    _this.list = resp.data.data;
                    if (resp.data.status==200){
                        setTimeout(() => {
                            // 加载状态结束
                            _this.loading = false;
                            _this.finished = true;
                        },1000)
                    }
                });
            },

            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
        }
    }
</script>

<style scoped>
    .van-doc-demo-block__title {
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: bolder;
        font-size: 20px;
        line-height: 16px;
    }
    h2 {
        font-family: 宋体;
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
</style>