<template>
    <div>
        <van-nav-bar
                class="icon-back"
                title="个人信息"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-form @submit="onsubmit">
            <van-field name="uploader" label="上传头像">
                <template #input>
                    <van-uploader v-model="uploader" :max-count="1"/>
                </template>
            </van-field>
            <van-field
                    v-model="name"
                    name="name"
                    type="text"
                    label="姓名"
                    placeholder="请输入姓名"
            />
            <van-cell-group>
                <van-field
                        v-model="phone"
                        name="phone"
                        label="手机号"
                        placeholder="请输入手机号"
                />
            </van-cell-group>
            <van-field
                    v-if="role=='REPAIRMAN'"
                    readonly
                    clickable
                    name="subject"
                    :value="subject"
                    label="维修种类"
                    placeholder="点击选择"
                    @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="subjects"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                />
            </van-popup>
            <label hidden>
                <van-field name="repairTypeId" :value="repairTypeId"></van-field>
            </label>
            <div v-if="editPas">
                <van-field
                        v-model="password"
                        name="password"
                        type="password"
                        label="密码"
                        placeholder="请输入密码"
                />
                <van-field
                        v-model="password1"
                        name="password1"
                        type="password"
                        label="确认密码"
                        placeholder="请再次输入密码"
                />
            </div>

            <div style="margin: 16px">
                <van-button round block type="info" native-type="submit">修改信息</van-button>
<!--                <van-button round block type="info" native-type="submit">修改</van-button>-->
            </div>
        </van-form>
                <van-button round type="primary" @click="editPass" style="width: 93.5%">修改密码</van-button>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    import AreaList from "../api/area";
    export default {
        created() {
            let _this = this;
            let token =  sessionStorage.getItem("token");
            console.log(token);
            axios.defaults.headers.common["satoken"] = token;
            axios.get('http://43.139.145.191:8081/user/getUserInfo').then((resp) =>{
                this.role = resp.data.data.user.role;
                this.uploader.push({url:resp.data.data.avatarPath.downloadUrl});
                this.name = resp.data.data.user.name;
                this.phone = resp.data.data.user.phone;
                if (resp.data.data.user.repairType!=null)
                this.subject = resp.data.data.user.repairType.name;
            })
            axios.get('http://43.139.145.191:8081/user/getAllRepairType?page=1&pageSize=20').then((resp) =>{
                let subjects = [];
                let subjectsId = []
                for (let i = 0 ; i<resp.data.data.length; i++){
                    subjects.push(resp.data.data[i].name);
                    subjectsId.push(resp.data.data[i].id);
                }
                this.subjectsId = subjectsId;
                this.subjects = subjects;
            })
        },
        data() {
            return {
                user:'',
                role:'',
                repairmanList:[],
                password:null,
                password1:null,
                phone:null,
                name:null,
                repairTypeId:null,
                uploader: [],
                value: '',
                showArea: false,
                areaList: AreaList,
                showPicker: false,
                subjects: [],
                subjectsId:[],
                subject:'',
                editPas:false,
            };
        },
        methods: {
            editPass(){
              this.editPas = true;
            },
            onConfirm(value,index) {
                this.subject = value;
                this.repairTypeId = this.subjectsId[index];
                this.showPicker = false;
            },

            tolast(){
                this.$router.go(-1);
            },
            onsubmit(values){
                var _this = this;
                let formData = new FormData()
                let token =  sessionStorage.getItem("token");
                axios.defaults.headers.common["satoken"] =  token;
                //console.log(values)
                if(this.password!=this.password1){
                    Toast('密码输入两次不一致！');
                } else if (this.uploader.length==0&&this.password==null&&this.phone==null){
                    Toast("没有填写需要修改的信息！")
                } else {
                    if (typeof(values.repairTypeId) == "undefined" ){
                        values.repairTypeId = null;
                    }
                    if (values.repairTypeId===""){
                        values.repairTypeId=null;
                    }
                    if (values.phone===""){
                        values.phone=null;
                    }
                    if (values.name==""){
                        values.name=null;
                    }else {
                        values.name = "\"" + values.name + "\"";
                    }
                    console.log(this.uploader[0].file)
                    if (values.password=='null'||values.password==null||values==''){
                        values.password=null;
                        formData.append('json',"{\n" +
                            "\"repairTypeId\":" +values.repairTypeId+
                            ",\"name\":" +values.name+
                            ",\"phone\":"+values.phone+"}");
                        if (values.uploader.length!=0){
                            formData.append("avatar",_this.uploader[0].file);
                            _this.uploader[0].status = 'uploading';
                            _this.uploader[0].message = '上传中...';
                            setTimeout(() => {
                                _this.uploader[0].status = 'done';
                                _this.uploader[0].message = '上传成功';
                            }, 2500);
                        }
                    } else {
                        values.password = "\"" + values.password + "\"";
                        formData.append('json',"{\n" +
                            "\"repairTypeId\":" +values.repairTypeId+
                            ",\"password\":" +values.password+
                            ",\"name\":" +values.name+
                            ",\"phone\":"+values.phone+"}");
                        if (values.uploader.length!=0){
                            formData.append("avatar",_this.uploader[0].file);
                            _this.uploader[0].status = 'uploading';
                            _this.uploader[0].message = '上传中...';
                            setTimeout(() => {
                                _this.uploader[0].status = 'done';
                                _this.uploader[0].message = '上传成功';
                            }, 2500);
                        }
                    }
                    axios.post('http://43.139.145.191:8081/user/updateUser',formData).then(function () {
                        Toast.success('修改成功');
                        _this.$notify({
                            type: "success",
                            message: "成功",
                            duration: 2000,
                        });
                    })
                }
            }
        },
    };
</script>

<style scoped>
    .icon-back{
        width: 95%;
        position: absolute;
        left: 0px;
        top:15px
    }
</style>

