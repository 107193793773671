<template>
    <div>
        <p class="title" >登录</p>
        <van-image
                round
                width="6rem"
                height="6rem"
                src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fi1.hdslb.com%2Fbfs%2Fface%2F401a6fb2434029d8d9e1332d069775300b2e1320.jpg&refer=http%3A%2F%2Fi1.hdslb.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639715927&t=b2146fbc0336127396421d5aed978b86"
        />
        <van-form>
            <van-field
                    v-model="account"
                    name="用户名"
                    label="用户名"
                    placeholder="用户名"
                    :rules="[{ required: true, message: '请填写用户名' }]"
            />
            <van-field
                    v-model="password"
                    type="password"
                    name="密码"
                    label="密码"
                    placeholder="密码"
                    :rules="[{ required: true, message: '请填写密码' }]"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" @click="onSubmit">登录</van-button>
            </div>
            <div class="reg">
                <div @click="toRegister">没有账号？立即注册</div>
            </div>
        </van-form>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    // import {Vue} from 'vue'
    import GLOBAL from '@/api/global_variable';
    export default {
        created() {
            // console.log(sessionStorage.getItem('token'));
            if (sessionStorage.getItem('token')!=null){
                axios.defaults.headers.common["satoken"] =  sessionStorage.getItem('token');
                let _this = this;
                axios.get('http://43.139.145.191:8081/user/getUserInfo').then(function (resp) {
                    if (resp.data.data.user.role=="CUSTOM"){
                        setTimeout(() => {
                            _this.$router.push("/user_repair");
                        }, 1000)
                    }else if(resp.data.data.user.role=="REPAIRMAN"){
                        setTimeout(() => {
                            _this.$router.push("/RepairHome");
                        }, 1000)
                    }else if (resp.data.data.user.role=="ADMIN"){
                        setTimeout(() => {
                            _this.$router.push("/AdminHome");
                        }, 1000)
                    }
                })
            }
        },
        name: "Login",
        data() {
            return {
                account: '',
                password: '',
            };
        },
        methods: {
            onSubmit() {
                /*console.log('submit', values);*/
                let login = {
                    account:this.account,
                    password:this.password
                }
                const _this = this
                axios.post('http://43.139.145.191:8081/user/login',login).then(function (resp) {
                    if (resp.data.status===200){
                        Toast.success('登录成功');
                        sessionStorage.setItem("token",resp.data.data.tokenValue);
                        // localStorage.setItem("token",resp.data.data.tokenValue);
                        let token =  sessionStorage.getItem("token");
                        axios.defaults.headers.common["satoken"] =  token;
                        //获取用户权限类型
                        axios.get('http://43.139.145.191:8081/user/getUserInfo').then(function (resp) {
                            if (resp.data.data.user.role=="CUSTOM"){
                                setTimeout(() => {
                                    _this.$router.push("/user_repair");
                                }, 1000)
                            }else if(resp.data.data.user.role=="REPAIRMAN"){
                                setTimeout(() => {
                                    _this.$router.push("/RepairHome");
                                }, 1000)
                            }else if (resp.data.data.user.role=="ADMIN"){
                                setTimeout(() => {
                                    _this.$router.push("/AdminHome");
                                }, 1000)
                            }
                        })
                    }else if(resp.data.status===500){
                        Toast.fail(resp.data.msg)
                    } else {
                        Toast.fail('账号或密码错误');
                    }
                })
            },
            toRegister(){
                this.$router.push('/Register')
            }
        }
    }

</script>

<style scoped>
    .title {
        /* border-radius: 15px; */
        size:1px;
        height: 50px;
        line-height: 50px;
        background-color: #20a0ff;
        color: #fff;
        text-align: center;
    }

</style>

