<template>
    <div>
        <van-nav-bar
                class="icon-back"
                title="详情信息"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-steps direction="vertical" :active="0">
            <van-step>
                <h3>【订单】已完成维修，欢迎下次为您服务</h3>
                <p>2021-11-29 15:20</p>
            </van-step>
            <van-step>
                <h3>【订单】维修人员已经到达</h3>
                <p>2021-11-29 13:40</p>
            </van-step>
            <van-step>
                <h3>【订单】维修人员正在赶往</h3>
                <p>2021-11-29 12:40</p>
            </van-step>
            <van-step>
                <h3>【订单】已分配维修人员</h3>
                <p>2021-11-29 10:00</p>
            </van-step>
            <van-step>
                <h3>已经接单</h3>
                <p>2021-11-29 09:30</p>
            </van-step>
        </van-steps>
    </div>
</template>

<script>
    export default {
        name: "OrderStep",
        methods:{
            tolast(){
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>