<template>
    <div id="tab-bar">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: "TabBar"
    }
</script>

<style scoped>
    #tab-bar {
        display: flex;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 60px;
        background-color: #f5efef;
    }
</style>