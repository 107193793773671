<template>
    <div style="background-color: rgb(247,248,250);height: 889px;text-align: left">
        <van-nav-bar
                class="icon-back"
                title="查看用户"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-cell is-link @click="showPopup">
            选择用户类型
        </van-cell>
        <van-popup round v-model="show" :style="{ height: '30%',width: '60%'}">
            <van-radio-group v-model="radio" style="position: relative;top: 40%;left: 30%">
                <van-radio name="1" icon-size="24px" @click="clickRadio(radio)" >
                    <van-icon size="24px" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" />
                    用 &nbsp;&nbsp;户
                </van-radio>
                <van-radio name="2" icon-size="24px" @click="clickRadio(radio)">
                    <van-icon size="24px" name="manager-o" />
                    维修员
                </van-radio>
            </van-radio-group>
        </van-popup>
        <van-cell-group inset class="van-cell-group--inset" v-for="item in list">
            <van-cell is-link class="van-cell" :title="item.name" :value="item.id" @click="showPopup2(item.id)" />
        </van-cell-group>
        <van-popup round v-model="show2" :style="{ height: '24%',width: '60%'}">
            <van-cell-group v-model="list2">
                <van-cell
                        center
                        v-model="list2.account"
                        name="username"
                        title="用户名"
                />
                <van-cell
                        v-model="list2.role"
                        name="role"
                        title="用户类型"
                />
                <van-cell
                        v-model="list2.name"
                        name="name"
                        title="姓名"
                />
                <van-cell
                        v-model="list2.phone"
                        name="phone"
                        title="联系电话"
                />
                <van-cell
                        v-if="list2.repairType!==''"
                        v-model="list2.repairType"
                        name="repairType"
                        title="维修类别"
                />
            </van-cell-group>
        </van-popup>
    </div>
</template>

<script>
    import {Toast} from "vant";

    export default {
        name: "AdminFindUser",
        data() {
            return {
                list:[],
                list2:{
                    account:'',
                    role:'',
                    name:'',
                    phone:'',
                    repairType:''
                },
                radio: '0',
                show2:false,
                show: true,
                error: false,
                loading: false,
                finished: false,
                refreshing: false,
            };
        },
        methods:{
            clickRadio(radio){
                let role;
                if (radio==='1'){
                    role="CUSTOM"
                    axios.get('http://43.139.145.191:8081/admin/getAllUserByRole?page=1&pageSize=20&roleString='+role).then((resp) => {
                        this.list = resp.data.data;
                    })
                }else if (radio==='2'){
                    role="REPAIRMAN"
                    axios.get('http://43.139.145.191:8081/admin/getAllUserByRole?page=1&pageSize=20&roleString='+role).then((resp) => {
                        this.list = resp.data.data;
                    })
                }
                // Toast(role);
                this.show=false;
            },
            showPopup() {
                this.show = true;
            },tolast(){
                this.$router.go(-1)
            },showPopup2(id){
                this.show2 = true;
                this.list2.account = '';
                this.list2.role = '';
                this.list2.name = '';
                this.list2.phone = '';
                this.list2.repairType = '';
                axios.get('http://43.139.145.191:8081/admin/getUserById?id='+id).then((resp) =>{
                    this.list2.account = resp.data.data.user.account;
                    this.list2.role = resp.data.data.user.role;
                    this.list2.name = resp.data.data.user.name;
                    this.list2.phone = resp.data.data.user.phone;
                    if (resp.data.data.user.repairType!=null)
                    this.list2.repairType = resp.data.data.user.repairType.name;
                })
            }
        }
    }
</script>

<style scoped>

</style>