<template>
    <div>
        <div class="demo-nav__title">管理员系统</div>
        <ul>
            <li v-for="item in minearry" class="mineitem demo-home-nav__block"

                @click="itemclick(item)" :key="item.label">

                <van-icon :name="item.icon" size="26px"/>

                <span class="minetitle">{{item.label}}</span>

                <van-icon class="van-icon-arrow"></van-icon>

                <i class="cubeic-arrow"></i>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "AdminHome",
        data(){

            return{

                minearry:[

                    {label:'订单审核',icon:'user-o',type: 'AdminOrder'},

                    {label:'查看投诉',icon:'orders-o',type: 'AdminComplain'},

                    {label:'管理维修种类',icon:'add-o',type: 'AdminRepairType'},

                    {label:'查看用户',icon:'eye-o',type: 'AdminFindUser'},

                    {label:'退出登录',type:'exit',icon: 'revoke'},

                ]

            }

        },
        methods:{

            itemclick(item){

                if(item.type==='exit'){

                    this.$store.commit('settoken','')

                    sessionStorage.removeItem('token')

                    let _this = this;
                    axios.post('http://43.139.145.191:8081/user/logout').then(function (resp) {
                        if (resp.data.status==200){
                            _this.$router.push('/login')
                        }
                    })

                }else if(item.type==='AdminOrder'){
                    this.$router.push({path:'/'+item.type})
                }else if(item.type==='AdminRepairType'){
                    this.$router.push({path:'/'+item.type})
                }else if(item.type==='AdminComplain'){
                    this.$router.push({path:'/'+item.type})
                }else if(item.type==='AdminFindUser'){
                    this.$router.push({path:'/'+item.type})
                }
            }
        }
    }
</script>

<style scoped>
    .mineitem{
        font-family: "微軟正黑體 Light";

        font-weight: bold;

        margin-top: 40px;

        font-size:  20px;

        text-align: left;

        height:  50px;

        line-height: 50px;

        padding-left: 5%;

        border-bottom: 1px solid #eee;
    }

    .minetitle{
        display:inline-block;
        width:85%;
    }

    .demo-nav__title {
        font-weight: 600;
        font-size: 25px;
        text-transform: capitalize;
        display: block;
    }

    .demo-home-nav__block {
        margin: 30px 23px 80px;
        padding-left: 20px;
        color: #323233;
        font-weight: 600;
        font-size: 20px;
        line-height: 50px;
        /*background: #f1f2f6;*/
        border-radius: 99px;
        -webkit-transition: background 0.3s;
        transition: background 0.3s;
    }
</style>