<template>
    <div style="background-color: rgb(247,248,250);height: 889px;text-align: left">
        <van-nav-bar
                class="icon-back"
                title="订单详情"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-swipe style="height: 100px;" class="my-swipe"   indicator-color="white" @change="onChange" :loop="false">
            <van-swipe-item v-for="item in path">
                <van-image
                    width="200"
                    height="100"
                    :src=item
                />
            </van-swipe-item>
            <template #indicator>
                <div class="custom-indicator">{{ current + 1 }}/{{path.length}}</div>
            </template>
        </van-swipe>

        <h2 class="van-doc-demo-block__title">订单详细信息</h2>
        <van-cell-group inset>
            <van-cell
                    center
                    v-model="name"
                    name="name"
                    title="姓名"
                    label="报修人的姓名"
            />
            <van-cell
                    v-model="address"
                    name="address"
                    title="地址"
                    label="报修人地址"
            />
            <van-cell
                    v-model="repairName"
                    name="repairName"
                    title="维修员"

            />
            <van-cell
                    v-model="problem"
                    name="problem"
                    title="问题描述"
            />
            <van-cell
                    v-model="repairType"
                    name="repairType"
                    title="维修类别"
            />
        </van-cell-group>
        <div style="margin: 40px;" v-if="this.role=='CUSTOM'">
            <van-button v-if="showFeed!=true" round block type="primary" native-type="submit" @click="Feed">投诉订单</van-button>
            <van-button v-if="showFeed" disabled round block type="primary" native-type="submit" @click="Feed">投诉订单</van-button>
            <br>
            <van-button round block type="danger" native-type="submit" @click="Delete">删除订单</van-button>
        </div>

    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";
    import AreaList from "../api/area";

    export default {
        name: "OrderInfo",
        created() {
            let id = JSON.parse(this.$route.query.id)
            let _this = this;
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/user/getOrderById?id='+id).then(function (response) {
                if (response.data.status==200){
                    console.log(response.data.data);
                    _this.id = response.data.data.order.id;
                    if (response.data.data.imgPaths.length!==0){
                        for (let i = 0;i<response.data.data.imgPaths.length;i++){
                            _this.path.push(response.data.data.imgPaths[i].downloadUrl);
                        }
                    }
                    _this.name = response.data.data.order.customName;
                    if (response.data.data.order.repairman != null)
                    _this.repairName = response.data.data.order.repairman.name;
                    _this.problem = response.data.data.order.problem;
                    _this.repairType = response.data.data.order.repairType;
                    let x = "";

                    let ads = "";//存详细地址
                    x = response.data.data.order.province;
                    x+="0000";//省

                    ads += AreaList.province_list[x];
                    x = response.data.data.order.province+response.data.data.order.city;
                    x+="00";//市

                    if (ads!=AreaList.city_list[x])//如果省和市不相同
                    ads += AreaList.city_list[x];
                    x = response.data.data.order.province+response.data.data.order.city+response.data.data.order.area;//区&县

                    ads += AreaList.county_list[x];
                    let detail = response.data.data.order.address.toString();
                    let change = detail.split(",");
                    ads += change[2];//完整地址
                    _this.address = ads;
                }
            })
            axios.get('http://43.139.145.191:8081/user/getUserInfo').then((resp) =>{
                this.role = resp.data.data.user.role;
                console.log(this.role)
            })
            axios.get('http://43.139.145.191:8081/custom/existComplaint?id='+id).then((resp) =>{
                this.showFeed = resp.data.data;
            })

        },
        data(){
            return{
                showFeed:'',
                role:'',
                current: 0,
                id:'',
                path:[],
                name:'',
                address:'',
                repairName:'',
                problem:'',
                repairType:''
            }
        },
        methods:{
            onChange(index) {
                this.current = index;
            },
            tolast(){
                history.go(-1);
            },
            Delete(){
                Dialog.confirm({
                    title: '确认通知',
                    message: '确定删除订单吗',
                })
                    .then(() => {
                        //Toast("删除成功");
                        // let Id = JSON.stringify(this.id)
                        let _this = this;
                        axios.delete('http://43.139.145.191:8081/custom/delOrder', {
                            data: {
                                id: parseInt(_this.id)
                            }
                        }).then(function (resp) {
                            if (resp.data.status==200){
                                Toast("删除成功");
                                setTimeout(() => {
                                    history.go(-1);
                                },2000)
                            }
                        })
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            Feed(){
                this.$router.push({path:"/UserFeedback",query:{id:this.id}})
            }
        }
    }
</script>

<style scoped>
    .van-doc-demo-block__title {
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: bolder;
        font-size: 20px;
        line-height: 16px;
    }
    h2 {
        font-family: 宋体;
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        /*background-color: #39a9ed;*/
    }
    .custom-indicator {
        position: absolute;
        right: 5px;
        bottom: 5px;
        padding: 2px 5px;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.1);
    }
</style>