<template>
    <div>
        <van-nav-bar
                class="icon-back"
                title="注册"
                left-text="返回"
                left-arrow
                @click-left="tologin"
        />
        <van-form>
            <van-cell-group>
                <van-field
                        v-model="account"
                        name="account"
                        required
                        label="用户名"
                        placeholder="请输入用户名"
                />
                <van-field
                        v-model="name"
                        name="name"
                        required
                        label="姓名"
                />
                <van-field
                        v-model="phone"
                        required
                        label="手机号"
                        placeholder="请输入手机号"
                        :rules="[
                         { required: true },
                         { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
        ]"
                />
                <van-field
                        v-model="password"
                        required
                        type="password"
                        label="密码"
                        placeholder="请输入密码"
                />
                <van-field
                        v-model="password1"
                        required
                        type="password"
                        label="确认密码"
                        placeholder="请再次输入密码"
                />
                <van-cell required style="position: relative;right: 75px" center title="选择用户类型">
                    <van-radio-group v-model="radio" direction="horizontal">
                        <van-radio name="1" icon-size="24px" @click="clickRadio(radio)" >
                            <van-icon size="24px" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" />
                            用 &nbsp;&nbsp;户
                        </van-radio>
                        <van-radio name="2" icon-size="24px" @click="clickRadio(radio)">
                            <van-icon size="24px" name="manager-o" />
                            维修员
                        </van-radio>
                    </van-radio-group>
                </van-cell>

                <van-field
                        v-if="role==='REPAIRMAN'"
                        readonly
                        clickable
                        name="subject"
                        :value="subject"
                        label="指定维修种类"
                        placeholder="点击选择"
                        @click="showPicker2 = true"
                />
                <van-popup v-model="showPicker2" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="subjects"
                            @confirm="onConfirm"
                            @cancel="showPicker2 = false"
                    />
                </van-popup>

            </van-cell-group>
        </van-form>
        <div style="margin: 16px">
            <van-button round block type="info" native-type="submit" @click="onsubmit">注册</van-button>
        </div>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    export default {
        created() {
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            let _this = this;
            axios.get('http://43.139.145.191:8081/user/getAllRepairType?page=1&pageSize=20').then(function (resp) {
                let subject = [];
                let subjectsId = []
                for (let i=0 ; i<resp.data.data.length ;i++){
                    subjectsId.push(resp.data.data[i].id);
                    subject.push(resp.data.data[i].name);
                }
                _this.subjectsId = subjectsId;
                _this.subjects = subject
            });
        },
        data() {
            return {
                repairTypeId:'',
                showPicker2:false,
                subject:'',
                subjects:[],
                subjectsId:[],
                role:'',
                radio: '0',
                name:'',
                account:'',
                phone:'',
                sms:'',
                password:'',
                password1:'',
                uploader: [{ url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fi1.hdslb.com%2Fbfs%2Fface%2F000616391a8854f6c551e8010f82a4e6f4aca26d.jpg&refer=http%3A%2F%2Fi1.hdslb.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639718432&t=dcdaefe942ea577d3b4699c0c55f9e69' }],
                value: '',
            };
        },
        methods: {
            onConfirm(value,index) {
                this.subject = value;
                this.repairTypeId = this.subjectsId[index];
                this.showPicker2 = false;
            },
            clickRadio(radio){
                if (radio==='1'){
                    this.role="CUSTOM"

                }else if (radio==='2'){
                    this.role="REPAIRMAN"

                }
            },
            tologin(){
                this.$router.go(-1);
            },
            onsubmit(){
                if(this.name==""||this.account==""||this.phone==""||this.password==""||this.password1==""){
                    Toast('注册失败！信息未完善');
                }
                else if(this.password!=this.password1){
                    Toast('密码输入两次不一致！');
                }
                else{
                    axios.post('http://43.139.145.191:8081/user/register',{
                        account: this.account,
                        id: 0,
                        name: this.name,
                        password: this.password.toString(),
                        phone: this.phone,
                        repairType: {
                            id: this.repairTypeId,
                            name: this.subject
                        },
                        role: this.role
                    }).then((resp) =>{
                        if (resp.data.status==200){
                            Toast.success('注册成功');
                            this.$notify({
                                type: "success",
                                message: "注册成功，3s后返回登录",
                                duration: 3000,
                            });
                            setTimeout(() => {
                                sessionStorage.clear("regis");
                                this.$router.go(-1);
                            }, 3000);
                        }else {
                            Toast.fail(resp.data.msg);
                        }

                    })
                }
            }
        },
    };
</script>

<style scoped>

</style>

