<template>
    <div>
        <form name="punchout_form" method="post" action="https://openapi.alipaydev.com/gateway.do?charset=UTF-8&method=alipay.trade.wap.pay&sign=xx&return_url=http%3A%2F%2Fngrok.sscai.club%2Falipay_trade_wap_pay_java_utf_8_war_exploded%2Freturn_url.jsp&notify_url=http%3A%2F%2Fngrok.sscai.club%2Falipay_trade_wap_pay_java_utf_8_war_exploded%2Fnotify_url.jsp&version=1.0&app_id=2016101700705301&sign_type=RSA2&timestamp=2020-01-08+14%3A09%3A58&alipay_sdk=alipay-sdk-java-3.3.0&format=json">
            <input type="hidden" name="biz_content" value="{&quot;body&quot;:&quot;购买测试商品0.01元&quot;,&quot;out_trade_no&quot;:&quot;20201814955421&quot;,&quot;product_code&quot;:&quot;QUICK_WAP_WAY&quot;,&quot;subject&quot;:&quot;手机网站支付测试商品&quot;,&quot;timeout_express&quot;:&quot;2m&quot;,&quot;total_amount&quot;:&quot;0.01&quot;}" />
            <input type="submit" value="立即支付" style="display:none" />
        </form>

    </div>
</template>
<!--<script>document.forms[0].submit();</script>-->
<script>
    import { Toast } from 'vant';
    export default {
        data() {
            return {
                username:'',
                phone:'',
                sms:'',
                satoken:'',
                uploader: [{ url: '' }],
                value: '',
            };
        },
        methods: {
            onSubmit(values){
                console.log(values)
                // let token =  this.satoken;
                // axios.defaults.headers.common["satoken"] =  token;
                // axios.post('填写你自己需要的测试的接口',values).then(function (resp) {
                //     if (resp.data.status==200){
                //         Toast.success("成功！");
                //     }
                // })
            }
        },
    };
</script>

<style scoped>

</style>

