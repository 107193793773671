<template>
    <div>
        <van-nav-bar
                class="icon-back"
                title="查看投诉"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-card
                    style="font-size: 15px; font-weight: bold"
                    v-for="(item,index) in list"
                    centered
                    :desc="'投诉人：'+item.order.customName"
                    :title="'被投诉订单：'+item.order.id"
                    :thumb="item.thumb"
            >
                <template #tags>
                    <van-tag plain type="danger" >
                        {{item.createDate}}
                    </van-tag>
                </template>
                <template #footer>
                    <van-button round type="info" size="small" @click="check(item.id)">查看</van-button>
                </template>
            </van-card>
        </van-pull-refresh>
    </div>
</template>

<script>
    import AreaList from '../api/area';
    import {Toast} from "vant";
    import { Dialog } from 'vant';
    import items from "../api/items";
    export default {
        name: "RepairHome",
        created() {
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/admin/getAllComplaint?page=1&pageSize=20').then((resp) =>{
                this.list = resp.data.data;
            })
        },
        data() {
            return {
                show: false,
                list:[],
                phones: [
                    {
                        desc:'电脑黑屏一直自己重启',
                        address:'湖南省长沙市',
                        thumb:'https://img01.yzcdn.cn/vant/ipad.jpeg',
                        tag:{
                            name: '电脑设备'
                        }
                    }
                ],
                error: false,
                loading: false,
                finished: false,
                refreshing: false,
            };
        },
        methods: {
            returnOrder(){
                Toast("退回")
            },
            tolast(){
                this.$router.go(-1)
            },
            showPopup() {
                this.show = true;
            },
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                setTimeout(() => {
                    if (this.refreshing) {
                        this.phones = [];
                        this.refreshing = false;
                    }
                    for (let i = 3; i < 10; i++) {
                        let list =
                            {
                                desc:'电脑黑屏一直自己重启',
                                address:'北京市',
                                thumb:'https://img01.yzcdn.cn/vant/ipad.jpeg',
                                tag:{
                                    name: '电脑设备'
                                }
                            }
                        ;
                        let list2 =
                            {
                                desc:'平板坏了不知什么原因',
                                address:'湖南省长沙市',
                                thumb:'https://img01.yzcdn.cn/vant/ipad.jpeg',
                                tag:{
                                    name: '电脑设备'
                                }
                            }
                        if (i%2===0)
                            this.phones.push(list);
                        else
                            this.phones.push(list2);

                    }
                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (this.phones.length >= 6) {
                        this.finished = true;
                    }
                }, 1000);
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            check(id){
                let data = JSON.stringify(id)
                this.$router.push({path:'/ComplaintInfo',query:{id:data}})
            },
            navClick(index){
                Toast(items.areas[index].areaCode);
            }
        },
    }
</script>

<style scoped>

</style>