<template>
    <div>
        <van-nav-bar
                class="icon-back"
                title="地址编辑"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-address-edit
                :area-list="areaList"
                show-delete
                show-set-default
                :address-info="addressInfo"
                save-button-text="修改"
                @save="onSave"
                @delete="onDelete"
        />
    </div>

</template>

<script>
    import AreaList from '../api/area';
    import { Toast } from 'vant';
    export default {
        name: "AddressEdit",
        created(){
            let data = JSON.parse(this.$route.query.item)
            console.log(data)
            this.addressInfo = data
            let index = data.address.indexOf('区')//取不到为-1
            if(index < 0) index = data.address.indexOf('县')
            this.addressInfo.addressDetail = data.address.substring(index+1)
        },
        data() {
            return {
                areaList: AreaList,
                addressInfo: null
            }
        },
        methods: {
            onSave(item) {
                // console.log(item);
                // const _this = this;
                axios.post('http://43.139.145.191:8081/custom/updateAddress', {
                    areaCode:item.areaCode,
                    id:item.id,
                    isDefault:item.isDefault,
                    name:item.name,
                    tel:item.tel,
                    detail:item.addressDetail
                }).then(function (resp) {
                    if(resp.data.status === 200){
                        let instance = Toast('修改成功');
                        setTimeout(() => {
                            history.go(-1)
                        }, 1000)
                    }
                })
            },
            onDelete(item) {
                axios.delete('http://43.139.145.191:8081/custom/delAddress?id='+item.id).then(function (resp) {
                    if(resp.data.status === 200){
                        let instance = Toast('删除成功');
                        setTimeout(() => {
                            history.go(-1)
                        }, 1000)
                    }
                })
            },
            tolast(){
                history.go(-1)
            }
        }
    }
</script>

<style scoped>

</style>