<template>
    <div class="message" style="background-color: rgb(247,248,250);height: auto;text-align: left">
        <div class="demo-nav__title" style="text-align: center">消息中心</div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :error.sync="error"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
            >
                <van-cell-group inset class="van-cell-group--inset" v-for="(item,index) in list">
                    <div style="padding: 10px;background-color: rgb(247,248,250);text-align: left"/>
                    <van-swipe-cell>
                        <van-cell center class="van-cell" style="font-size: 16px;height: 90px" :title="item.content" :value="item.createDate" />
<!--                        <template #right>-->
<!--                            <van-button square text="标记已读" type="primary" class="delete-button" style="height: 90px" @click="read(item.id)" />-->
<!--                        </template>-->
                    </van-swipe-cell>
                </van-cell-group>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import {Toast} from "vant";

    export default {
        created() {
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/user/getAllMessages?page=1&pageSize=100').then((resp) =>{
                this.list = resp.data.data;
                // this.list.push("");
            })
        },
        data() {
            return {
                list: [],
                error: false,
                loading: false,
                finished: false,
                refreshing: false,
            };
        },
        methods: {
            read(id){
                axios.post('http://43.139.145.191:8081/user/read',{id:id}).then((resp) =>{
                    if (resp.data.status==200){
                        Toast("已读")
                    }
                })
            },
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                setTimeout(() => {
                    if (this.refreshing) {

                        this.refreshing = false;
                    }

                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成

                    this.finished = true;

                }, 1000);
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
        },
    };
</script>

<style scoped>
    .mineitem{
        font-family: "微軟正黑體 Light";

        font-weight: bold;

        margin-top: 40px;

        font-size:  20px;

        text-align: left;

        height:  60px;

        line-height: 50px;

        padding-left: 5%;

        border-bottom: 1px solid #eeecec;
    }
    .demo-nav__title {
        font-weight: 600;
        font-size: 25px;
        text-transform: capitalize;
        display: block;
    }
</style>