<template>
    <div>
        <van-nav-bar
                class="icon-back"
                title="地址新增"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-address-edit
                class="mineitem"
                :area-list="areaList"
                show-delete
                show-set-default
                :search-result="searchResult"
                :area-columns-placeholder="['请选择', '请选择', '请选择']"
                @save="onSave"
                @delete="onDelete"
        />
    </div>
</template>

<script>
    import AreaList from '../api/area';
    import { Toast } from 'vant';
    export default {
        name: "AddressNew",
        data() {
            return {
                areaList: AreaList,
                searchResult: [],
            }
        },
        methods: {

            onSave(item) {
                const _this = this
                console.log(item);
                let ads = {
                    areaCode:item.areaCode,
                    detail:item.addressDetail,
                    phone:item.tel,
                    name:item.name,
                    defaultStatus:item.isDefault
                }
                let token =  sessionStorage.getItem("token");
                axios.defaults.headers.common["satoken"] =  token;
                axios.post('http://43.139.145.191:8081/custom/createAddress',ads).then(function (resp) {
                    if(resp.data.status == 200){
                        let instance = Toast.success('添加成功');
                        setTimeout(() => {
                            instance.close();
                             _this.$router.push('/user_repair')
                        }, 1000)
                    }
                })
            },
            onDelete() {
                history.go(-1)
            },
            tolast(){
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>