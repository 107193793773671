import { render, staticRenderFns } from "./AdminComplain.vue?vue&type=template&id=7d381838&scoped=true&"
import script from "./AdminComplain.vue?vue&type=script&lang=js&"
export * from "./AdminComplain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d381838",
  null
  
)

export default component.exports