<template>
    <div>
        <van-nav-bar
                class="icon-back"
                title="我的报修"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-tabs class="mineitem" v-model="activeName" type="line" animated>
            <van-tab title="进行中" name="a">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-card
                            style="font-size: 15px; font-weight: bold"
                            v-for="(item,index) in list2"
                            centered
                            :desc="item.customName"
                            :title="item.repairType"
                            :id="item.id"
                    >
                        <template #footer>
                            <van-button round type="info" size="small" @click="check(item.id)">查看</van-button>
                            <van-button round type="primary" size="small" @click="finish(item.id)">完成</van-button>
                        </template>
                    </van-card>
                </van-pull-refresh>
            </van-tab>
            <van-tab title="已完成" name="b">
                <van-card
                        style="font-size: 15px; font-weight: bold"
                        v-for="(item,index) in list3"
                        centered
                        :desc="item.customName"
                        :title="item.repairType"
                        :id="item.id"
                >
                    <template #footer>
                        <van-button round type="info" size="small" @click="check(item.id)">查看</van-button>
                        <van-button round type="primary" size="small" @click="SetPrice(item.id)">设定价格</van-button>
                    </template>
                </van-card>
            </van-tab>
            <van-tab title="已支付" name="b">
                <van-card
                        style="font-size: 15px; font-weight: bold"
                        v-for="(item,index) in list4"
                        centered
                        :desc="item.customName"
                        :title="item.repairType"
                        :id="item.id"
                >
                    <template #footer>
                        <van-button round type="info" size="small" @click="check(item.id)">查看</van-button>
                    </template>
                </van-card>
            </van-tab>
            <van-tab title="待接单" name="c">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-card
                            style="font-size: 15px; font-weight: bold"
                            v-for="(item,index) in list"
                            centered
                            :desc="item.customName"
                            :title="item.repairType"
                            :id="item.id"
                    >
                        <template #footer>
                            <van-button round type="info" size="small" @click="pick(item.id)">接单</van-button>
                            <van-button round type="info" size="small" color="red" @click="cancel(item.id)">拒绝</van-button>
                        </template>
                    </van-card>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>
        <div>
            <van-popup v-model="show" round position="bottom" :style="{ height: '30%' }">
                <van-form @submit="onSubmit" style="position: relative;top: 20%">
                    <van-field
                            v-model="price"
                            name="price"
                            label="设定价格"
                            placeholder="输入价格"
                    />
                    <div style="margin: 40px;">
                        <van-button round block type="info" native-type="submit">确定</van-button>
                    </div>
                </van-form>
            </van-popup>
        </div>
        <div>
            <van-popup v-model="show2" :style="{ height: '305px',width:'300px' }">
                <img :src="img">
            </van-popup>
        </div>
    </div>

</template>

<script>
    import {Toast} from "vant";
    import { Dialog } from 'vant';

    export default {
        created() {
            let _this = this;
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/repairman/getAssignOrderById?page=1&pageSize=20').then(function (resp) {
                _this.list = resp.data.data;
            })
            axios.get('http://43.139.145.191:8081/repairman/getHistoryOrders?page=1&pageSize=20&status=4').then(function (resp) {
                _this.list3 = resp.data.data;
            })
            axios.get('http://43.139.145.191:8081/repairman/getHistoryOrders?page=1&pageSize=20&status=3').then(function (resp) {
                _this.list2 = resp.data.data;
            })
            axios.get('http://43.139.145.191:8081/repairman/getHistoryOrders?page=1&pageSize=20&status=5').then(function (resp) {
                _this.list4 = resp.data.data;
            })
        },
        methods:{
            onSubmit(){
                axios.post('http://43.139.145.191:8081/repairman/payForOrder?total_amount='+this.price+'&order_id='+this.orderId).then((response)=>{
                    if (response.data.status==200){
                        this.show2 = true;
                        this.img = response.data.data;
                    }
                })
            },
            SetPrice(id){
                this.show = true;
                this.orderId = id;
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                setTimeout(() => {
                    if (this.refreshing) {
                        this.list = [];
                        this.refreshing = false;
                    }
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    let _this = this;
                    axios.get('http://43.139.145.191:8081/repairman/getAssignOrderById?page=1&pageSize=20').then(function (resp) {
                        _this.list = resp.data.data;
                        if (resp.data.status==200){
                            this.finished = true;
                        }
                    })
                }, 1000);
            },
            tolast(){
                this.$router.go(-1);
            },
            pick(id){
                this.show = true;
                axios.post('http://43.139.145.191:8081/repairman/pickOrder',{orderId:id,pick:true}).then(function (resp) {
                    //_this.list = resp.data.data;
                    if (resp.data.status==200){
                        Toast.success("接单成功");
                        window.location.reload();
                    }
                })
            },
            cancel(id){
                Dialog.confirm({
                    title: '确认通知',
                    message: '确定拒绝吗',
                }).then(() =>{
                    axios.post('http://43.139.145.191:8081/repairman/pickOrder',{orderId:id,pick:false}).then(function (resp) {
                        //_this.list = resp.data.data;
                        if (resp.data.status==200){
                            Toast.success("拒绝成功！");
                            window.location.reload();
                        }
                    })
                })

            },
            check(id){
                this.show = true;
                let data = JSON.stringify(id)
                this.$router.push({path:'/OrderInfo',query:{id:data}})
            },finish(id){
                Dialog.confirm({
                    title: '确认通知',
                    message: '是否完成',
                }).then(() =>{
                    axios.post('http://43.139.145.191:8081/repairman/finishOrder',{orderId:id}).then(function (resp) {
                        //_this.list = resp.data.data;
                        if (resp.data.status==200){
                            Toast.success("完成订单！");
                            window.location.reload();
                        }
                    })
                })
            }
        },
        data() {
            return {
                img:'',
                price:'',
                show:false,
                show2:false,
                list:[],
                list2:[],
                list3:[],
                list4:[],
                orderId:'',
                activeName: 'a',
                refreshing: false,
                finished: false,
                loading: false,
            };
        },
    };
</script>

<style scoped>

</style>