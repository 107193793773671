<template>
    <div style="background-color: rgb(247,248,250);height: 889px;text-align: left">
        <van-nav-bar
                class="icon-back"
                title="维修种类"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <h2 class="van-doc-demo-block__title">维修种类管理</h2>

            <van-cell-group inset class="van-cell-group--inset" v-for="(item,index) in list">
                <van-swipe-cell>
                <van-cell class="van-cell" :title="item.name" :value="index+1" />
                <template #right>
                    <van-button square text="删除" type="danger" class="delete-button" @click="Delete(item.id)" />
                </template>
                </van-swipe-cell>
            </van-cell-group>


        <van-button round
                    style="margin-left:25px;margin-top: 30px;width: 90%;text-align: center"
                    type="primary"
                    block @click="showPopup" >
            添加种类
        </van-button>
        <div>
            <van-popup v-model="show" round position="bottom" :style="{ height: '30%' }">
                <van-form @submit="onSubmit" style="position: relative;top: 20%">
                    <van-field
                            v-model="name"
                            name="name"
                            label="维修种类名称"
                            placeholder="输入名称"
                    />
                    <div style="margin: 40px;">
                        <van-button round block type="info" native-type="submit">添加</van-button>
                    </div>
                </van-form>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import {Dialog, Toast} from 'vant';

    export default {
        name: "AdminRepairType",
        created() {
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            axios.get('http://43.139.145.191:8081/user/getAllRepairType?page=1&pageSize=20').then((resp) => {
                this.list = resp.data.data;
            })
        },
        data(){
            return{
                name:'',
                list:[],
                show: false,
            };
        },
        methods:{
            Delete(id){
                Dialog.confirm({
                    message: '确定删除吗？',
                }).then(() => {
                    axios.delete('http://43.139.145.191:8081/admin/delRepairType',{
                        data:{
                            id:parseInt(id)
                        }
                    }).then((resp) => {
                        if (resp.data.status==200){
                            Toast("删除成功");
                            setTimeout(()=>{
                                window.location.reload();
                            },300)
                        }
                    })
                });
            },
            tolast(){
                this.$router.go(-1);
            },
            showPopup() {
                this.show = true;
            },
            onSubmit(values) {
                Dialog.alert({
                    title: '系统通知',
                    message: '添加成功',
                }).then(() => {
                    axios.post('http://43.139.145.191:8081/admin/createRepairType',values).then((resp) =>{
                        if(resp.data.status==200){
                            setTimeout(()=>{
                                window.location.reload();
                            },500)
                        }
                    })
                    // on close
                });

            },
        }
    }
</script>

<style scoped>

    .van-cell-group--inset {
        margin: 0 16px;
        overflow: hidden;
        border-radius: 8px;
    }
    .van-doc-demo-block__title {
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: bolder;
        font-size: 20px;
        line-height: 16px;
    }
    h2 {
        font-family: 宋体;
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
</style>