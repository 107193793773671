<template>
    <div>
        <van-nav-bar
                class="icon-back"
                title="我的报修"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-tabs class="mineitem" v-model="activeName" type="line" animated>
            <van-tab title="进行中" name="a">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-card
                            style="font-size: 15px; font-weight: bold"
                            v-for="(item,index) in list2"
                            centered
                            :desc="item.customName"
                            :title="item.repairType"
                            :id="item.id"
                    >
                        <template #footer>
                            <van-button round type="info" size="small" @click="check(item.id)">查看</van-button>
                        </template>
                    </van-card>
                </van-pull-refresh>
            </van-tab>
            <van-tab title="待审核" name="b">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-card
                            style="font-size: 15px; font-weight: bold"
                            v-for="(item,index) in list"
                            centered
                            :desc="item.customName"
                            :title="item.repairType"
                            :id="item.id"
                    >
                        <template #footer>
                            <van-button round type="info" size="small" @click="check(item.id)">查看</van-button>
                        </template>
                    </van-card>
                </van-pull-refresh>
            </van-tab>
            <van-tab title="待接手" name="c">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-card
                            style="font-size: 15px; font-weight: bold"
                            v-for="(item,index) in list4"
                            centered
                            :desc="item.customName"
                            :title="item.repairType"
                            :id="item.id"
                    >
                        <template #footer>
                            <van-button round type="info" size="small" @click="check(item.id)">查看</van-button>
                        </template>
                    </van-card>
                </van-pull-refresh>
            </van-tab>

            <van-tab title="待支付" name="d">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-card
                            style="font-size: 15px; font-weight: bold"
                            v-for="(item,index) in list3"
                            centered
                            :desc="item.customName"
                            :title="item.repairType"
                            :id="item.id"
                    >
                        <template #footer>
                            <van-button round type="info" size="small" @click="check(item.id)">查看</van-button>

                        </template>
                    </van-card>
                </van-pull-refresh>
            </van-tab>

            <van-tab title="已支付" name="e">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-card
                            style="font-size: 15px; font-weight: bold"
                            v-for="(item,index) in list5"
                            centered
                            :desc="item.customName"
                            :title="item.repairType"
                            :id="item.id"
                    >
                        <template #footer>
                            <van-button round type="info" size="small" @click="check(item.id)">查看</van-button>
                            <van-button round type="info" plain  size="small" @click="Evaluation(item.id)" color="">评价</van-button>
                        </template>
                    </van-card>
                </van-pull-refresh>
            </van-tab>

        </van-tabs>

        <div>
            <van-popup v-model="show" round position="bottom" :style="{ height: '33%' }">
                <van-form @submit="onSubmit" style="position: relative;top: 20%">
                    <van-field
                            style="font-size: 20px"
                            name="content"
                            v-model="content"
                            rows="2"
                            autosize
                            label="评价内容"
                            type="textarea"
                            maxlength="99"
                            placeholder="请输入评价内容"
                            show-word-limit
                    />
                    <van-cell center class="mineitem" title="评分" style="margin-top: 10px;line-height: 10px;font-family: 'Avenir', Helvetica, Arial, sans-serif">
                        <van-rate size="25" v-model="value" @change="onChange" color="#ffd21e" />
                    </van-cell>

                    <div style="margin:30px;">
                        <van-button round block type="info" native-type="submit">确认</van-button>
                    </div>
                </van-form>
            </van-popup>
        </div>

    </div>
</template>

<script>
    import {Toast} from "vant";

    export default {
        created() {
            let _this = this;
            let token =  sessionStorage.getItem("token");
            axios.defaults.headers.common["satoken"] =  token;
            //0 待审核
            axios.get('http://43.139.145.191:8081/custom/getOrdersByStatus?page=1&pageSize=20&status=0').then(function (resp) {
                _this.list = resp.data.data;
            })
            //4 待支付
            axios.get('http://43.139.145.191:8081/custom/getOrdersByStatus?page=1&pageSize=20&status=4').then(function (resp) {
                _this.list3 = resp.data.data;
            })
            //3 进行中
            axios.get('http://43.139.145.191:8081/custom/getOrdersByStatus?page=1&pageSize=20&status=3').then(function (resp) {
                _this.list2 = resp.data.data;
            })
            //1 待接手
            axios.get('http://43.139.145.191:8081/custom/getOrdersByStatus?page=1&pageSize=20&status=1').then(function (resp) {
                _this.list4 = resp.data.data;
                if (resp.data.status==200){
                    axios.get('http://43.139.145.191:8081/custom/getOrdersByStatus?page=1&pageSize=20&status=2').then(function (resps) {
                        if (resps.data.data.length!=0){
                            for (let i=0;i<resps.data.data.length;i++){
                                _this.list4.push(resps.data.data[i]);
                            }
                        }
                    })
                }
            })
            //5 已支付
            axios.get('http://43.139.145.191:8081/custom/getOrdersByStatus?page=1&pageSize=20&status=5').then(function (resp) {
                _this.list5 = resp.data.data;
            })
        },
        methods:{
            showPopup() {
                this.show = true;
            },
            tolast(){
                this.$router.go(-1);
            },

            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                setTimeout(() => {
                    if (this.refreshing) {
                        this.list = [];
                        this.refreshing = false;
                    }
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    let _this = this;
                    axios.get('http://43.139.145.191:8081/custom/getOrdersByStatus?page=1&pageSize=20&status=0').then(function (resp) {
                        _this.list = resp.data.data;
                        if (resp.data.status==200){
                            this.finished = true;
                        }
                    })
                }, 1000);
            },
            buy(id){

            },
            check(id){
                this.show = true;
                let data = JSON.stringify(id)
                this.$router.push({path:'/OrderInfo',query:{id:data}})
            },Evaluation(id){
                this.show = true;
                this.id = id;
            },onSubmit(values){
                axios.post('http://43.139.145.191:8081/custom/createEvaluation',{orderId:this.id,content:values.content,level:this.value}).then((resp)=>{
                    if (resp.data.status==200){
                        Toast.success("订单评价完成");
                    }else {
                        Toast.fail(resp.data.msg)
                    }
                    this.show = false;
                })
            },onChange(value){
                this.value = value;
                // Toast(value)
            }
        },
        data() {
            return {
                id:'',
                value:'',
                content:'',
                show: false,
                list:[],
                list2:[],
                list3:[],
                list4:[],
                list5:[],
                activeName: 'a',
                refreshing: false,
                finished: false,
                loading: false,
            };
        },
    };
</script>

<style scoped>

</style>